.form {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  align-items: center;
}

h1 {
  text-align: center;
}

.topDiv {
  border: none;
  padding: 15px 0 15px 0;
  background-color: #eeeeee;
  margin: 10px 5px 10px 5px;
  min-height: 200px;

  // input[type='number'] {
  //   margin: 0 10px 0 10px;
  // }
  &.leftDiv {
    float: left;
  }

  &.higher {
    display: flow-root;
    min-height: 250px;
  }
}

.app-container {
  input[type='submit'] {
    background-color: #1976d2;
    border: none;
    margin: 0 2px 0 2px;
    font-size: 15px;
    font-weight: bold;
    min-width: 150px;
    color: white;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
  }

  input[type='text'] {
    text-align: center;
  }
}

th.gap {
  display: block;
  position: relative;
  margin-right: 90px;
  margin-left: 90px;
}

.cols {
  column-count: 2;
}

.cols1 {
  column-count: 1;
}

.middleContainer2 input[type='text'],
.middleContainer2 input[type='number'],
.middleContainer1 input[type='text'],
.middleContainer1 input[type='number'],
.middleContainer3 input[type='text'],
.middleContainer3 input[type='number'],
.middleContainer4 input[type='text'],
.middleContainer4 input[type='number'] {
  width: 150px;
}

.finishedTop {
  background-color: #ffffff;
  margin: 0 2px 0 2px;
  font-size: 15px;
  font-weight: bold;
  min-width: 150px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
}

.generalDiv {
  position: relative;
  background-color: #eeeeee;
  padding: 25px;
  margin: 5px;
  font-size: 13px;

  input {
    width: 60px;
    margin: 5px;
  }
}

.rowS {
  display: flex;
  flex-wrap: wrap;
  // padding: 0 4px;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.inputDiv {
  position: relative;
  border: solid 0.1px;
  margin: 2px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  flex: 10%;
  background-color: #f3f3f3;
}

.rtl {
  direction: rtl;
}

#contracts {
  direction: rtl;
  margin-top: 25px;
  font-size: 15px;
  text-align: center;

  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#slideLeft {
  position: relative;
  left: -800px;
  //   width: 600px;
  //   height: 370px;
  //   background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 3s forwards;
  animation-delay: 1s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;

  button {
    margin: 16px 16px 16px 0;
  }
}

.insidePricing,
#insideProjectPricing {
  float: right;
}

.aligned {
  text-align: center;
  display: block;
  margin: auto;
}

#updateProject,
#saveProject,
#getOtherProject {
  display: none;
}

.materialui-daterange-picker-makeStyles-root-184 {
  width: 400px !important;
}

.MuiBox-root {
  .disabled {
    opacity: 0.4;
  }
}
