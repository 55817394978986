.login {
  width: 100%;
  .login-form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 400px;

    .login-error-message {
      text-align: center;
      margin: 16px 0;
      font-size: 18px;
      font-weight: 600;
      color: rgb(173, 39, 39);
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .form-group {
        margin-bottom: 15px;
      }

      .input-control {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 55px;
        background: #ffffff;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
        margin-bottom: 3px;

        label {
          position: absolute;
          width: 41px;
          height: 19px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }

        input {
          display: flex;
          max-width: 100%;
          height: 55px;
          width: 390px;
          padding: 0 10px;
          border-radius: 6px;
          border-color: rgba(215, 215, 215, 1);
          border-width: 1px;
          border-style: solid;
          background-color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0%;
          text-align: left;
          font-family: Inter, sans-serif;

          &.error {
            border: 1px solid rgb(173, 39, 39);
          }
        }
      }

      .error-message {
        margin: 0;
        font-size: 12px;
        color: rgb(173, 39, 39);
      }

      .button {
        width: 103%;
        height: 55px;
        background: #000000;
        border-radius: 6px;
        cursor: pointer;

        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }

        .btn-text {
          display: contents;
          color: white;
          font-size: 18px;
        }
      }
    }
  }
}
