.table.project-table {
  border: 2px solid black;
  margin: unset;
  padding: 8px;

  .total {
    background-color: lightsteelblue;
  }

  .table__header.total {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .table-row {
    &:hover {
      cursor: unset;
      background-color: unset;
    }
  }
}
.table.inner-table {
  border: 2px solid #ccc;
  padding: 8px;
  margin: 0;
  .table-row {
    &:hover {
      cursor: unset;
      background-color: lightsteelblue;
    }
  }
}

.materialui-daterange-picker-makeStyles-dateRangePickerContainer-1 {
  position: absolute !important;
}

.materialui-daterange-picker-MuiList-root-257.materialui-daterange-picker-MuiList-padding-258 {
  display: none;
}

.materialui-daterange-picker-makeStyles-root-184 {
  width: 400px !important;
}
