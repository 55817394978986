.employee {
  max-width: 1200px;
  margin: 0 auto;

  .delete-user {
    margin-bottom: 24px;

    button {
      margin-right: 16px;
    }
  }

  .user-details {
    font-size: 18px;
    h4 {
      display: inline-block;
      margin: 8px 0;
    }
  }
}
