.dashboard-top {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  align-content: stretch;
  direction: rtl;
}

.dashboard-top .dashboard-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  width: 200px;
  padding: 8px;
}

.projectContainer .tableDiv {
  display: flex;
  position: relative;
  margin: 30px;
  direction: rtl;
  flex-direction: row;
  justify-content: space-evenly;
  table {
    border-collapse: collapse;
    //   width: 100%;
  }
  .table1,
  .table2 {
    text-align: center;
  }

  th,
  td {
    text-align: center;
    border: solid 0.1px;
    padding: 10px;
  }
  .totals {
    border-radius: 10px;
    box-shadow: 6px 8px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    background-color: #ffffff;
    color: black;
    margin: 5px 5px 5px 5px;
    /* display: table-cell; */
    /* position: relative; */
    border: solid 0.1px;
    font-size: 15px;
    height: 22px;
    padding: 10px;
  }

  .topTable {
    direction: rtl;
  }
}

.project,
.container {
  .noContract {
    color: red;
  }
}
