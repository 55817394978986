.progress-wrapper {
  display: flex;
  align-items: center;

  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;

    progress {
      font-size: 0.6em;
      line-height: 1.5em;
      text-indent: 0.5em;
      width: 150px;
      height: 1.8em;
      background: #fff;
    }
    progress::-moz-progress-bar {
      background: linear-gradient(to right, blue 105px, red 45px);
    }
    progress::-webkit-progress-value {
      background: linear-gradient(to right, blue 105px, red 45px);
    }
    progress {
      background: linear-gradient(to right, blue 105px, red 45px);
    }
  }
}
