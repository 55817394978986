.dashboard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  .dashboard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    width: 200px;
    padding: 8px;

    .hours-bank-item {
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .progress-wrapper {
      flex-direction: column;
    }
  }
}
