.topDiv {
  .warningMessage {
    color: red;
  }
  table {
    border-collapse: collapse;
    //   width: 100%;
  }

  th,
  td {
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    background-color: #357bc1;
    color: white;
    /* margin: 5px 5px 5px 5px; */
    /* display: table-cell; */
    /* position: relative; */
    border: solid;
    font-size: 15px;
    height: 22px;
  }

  select {
    width: 100%;
  }
}
.rtl {
  .autoSave {
    position: fixed;
    float: left;
    left: 25%;
    display: none;
    text-align: center;
    direction: ltr;
    color: white;
    background-color: #b9e7b9;
    border: none;
    font-size: 18px;
    opacity: 1;
    animation: fade 2s linear;
  }
  .noSave {
    position: fixed;
    float: left;
    left: 25%;
    display: none;
    text-align: center;
    direction: ltr;
    color: white;
    background-color: #f11616;
    border: none;
    font-size: 18px;
    opacity: 1;
    animation: fade 2s linear;
  }
}

input[type='submit'] {
  cursor: pointer;
}
