.add-project {
  // margin: 16px 0;
  padding: 8px;
  // border: 2px solid black;
  // width: 90%;

  form {
    padding: 16px;
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
      margin: 12px 0;
    }

    .company {
      display: flex;

      .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl,
      .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        width: 100%;
      }
    }

    .project-type {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }

    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      width: 250px;
    }

    .team {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .team-member-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 12px 0;

      &>* {
        margin: 4px;
        cursor: pointer;
      }
    }
  }
}