.table {
  margin: 20px;
  height: auto;
  overflow: hidden;
  
  .table__header,
  .table-row {
    display: grid;
    grid-template-columns: 20px repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
    padding: 20px 10px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    text-align: center;
  }

  .table-row:hover {
    cursor: pointer;
    background-color: #ccc;
  }

  .table__header {
    font-weight: 700;
  }
}

.muitable.table {
  margin: 0;
}