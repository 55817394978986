.days-grid {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 50% 50%;

    & > div, 
    & > input {
        border: 1px solid rgba($color: #000000, $alpha: 0.5);
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    & > input[disabled] {
        background-color: #ccc;
        font-weight: 700;
    }
}