.main_table {
    min-height: calc(100vh - 10px);
    font-size: 1.2rem;

    .main-table__header {
        display: grid;
        min-height: 50px;
        border-bottom: 1px solid #ccc;
        grid-template-columns: repeat(auto-fit, 100px);
        &>div {
            border-right: 1px solid #ccc;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 1px;
            font-size: 0.8rem;

            .sub-name {
                font-size: 0.6rem;
                font-weight: 400;
            }


            &:last-child {
                border-right: none;
            }
        }

        .long-text {
            font-size: 0.8rem;
            font-weight: 500;
            height: 95%;
        }
        
    }

    .main-table__content {
        border-left: 1px solid #ccc;
    }

    .row {
        display: grid;
        height: 30px;
        text-align: center;
        border-bottom: 1px solid #ccc;
        grid-template-columns: repeat(auto-fit, 100px);

        .project-name {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .cell {
            border-right: 1px solid #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8rem;
            width: 98%;
        }

        .transparant {
            border: none;
        }

        input {
            width: 100%;
            height: 90%;
            outline: none;
            border: none;
            // border-right: 1px solid #ccc;
        }
    }
}